// import {userConstants} from '../constants';
import { userService } from '../services';
import { userConstants } from "../constants/user.constants";
import { history } from '../helpers';
import { AxiosResponse } from 'axios';
export const authActions = {
  login,
  logout,
  changePassword,
  signup
};

function login(username: any, password: any, rememberMe: any) {
  return (dispatch: (arg0: { type: string; user?: any; error?: any; }) => void) => {
    dispatch(request({ username, password, rememberMe }));
    // @ts-ignore
    userService.login(username, password, rememberMe)
      .then(
        user => {
          console.log("err", user)
          if (user.error) {
            dispatch(failure(user.message));
          }
          else {
            dispatch(success(user));
          }

        },
        error => {
          console.log("error", error)
          dispatch(failure(error.toString()));
        }
      );
  };

  function request(user: { username: any; password: any; rememberMe: any; }) {
    return { type: userConstants.LOGIN_REQUEST, user }
  }

  function success(user: AxiosResponse<any>) {
    return { type: userConstants.LOGIN_SUCCESS, user }
  }

  function failure(error: any) {
    return { type: userConstants.LOGIN_FAILURE, error }
  }
}

function signup(username: any, email: any, password: any, role: any) {
  return (dispatch: (arg0: { type: string; user?: any; error?: any; }) => void) => {
    dispatch(request({ username, email, password }));
    // @ts-ignore
    userService.signup(username, password, email,role)
      .then(
        user => {
          console.log("user", user)
          if (user.error) {
            dispatch(failure(user.message));
          }
          else {
            dispatch(success(user));
          }
        },
        error => {
          console.log("error", error)
          dispatch(failure(error.toString()));
        }
      );
  };

  function request(user: { username: any; email: any; password: any; }) {
    return { type: userConstants.REGISTER_REQUEST, user }
  }

  function success(user: AxiosResponse<any>) {
    return { type: userConstants.REGISTER_SUCCESS, user }
  }

  function failure(error: any) {
    return { type: userConstants.REGISTER_FAILURE, error }
  }
}

function logout() {

  return (dispatch: (arg0: { type: string; }) => void) => {
    dispatch({ type: userConstants.LOGOUT })
  }
}

function changePassword(token: any, fields: any) {
  return (dispatch: (arg0: { type: string; user?: any; error?: any; }) => void) => {
    // @ts-ignore
    dispatch(request(token, fields));
    userService.changePassword(token, fields)
      //@ts-ignore
      .then((user: any) => {
        if (user.data && user.data.success) {
          dispatch(success(user));
          history.push('/sign-in');
        } else {
          dispatch(failure('Password update failed!'));
        }
      },
        (error: any) => {
          console.log("error", error)
          dispatch(failure('Password update failed!'));
        }
      );
  };

  function request(user: any) {
    return { type: userConstants.UPDATE_REQUEST, user }
  }

  function success(user: any) {
    return { type: userConstants.UPDATE_SUCCESS, user }
  }

  function failure(error: string) {
    return { type: userConstants.UPDATE_FAILURE, error }
  }
}
