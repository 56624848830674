
let baseUrlConfig = {
  // "baseURL": "http://52.66.14.233:3001",
  // "baseURL": "http://localhost:3000",
  // "imageUrl":"http://localhost:3000/",
  "imageUrl":"http://148.66.131.203:3001/",
  "baseURL":'http://148.66.131.203:3001',  
  // @ts-ignore
  "token": JSON.parse(localStorage.getItem('token'))

}
export default baseUrlConfig
