import * as React from "react";
import styled from "styled-components";

import {
  Grid,
  Hidden,
  ListItemProps as MuiListItemProps
} from "@material-ui/core";

interface ListItemProps extends MuiListItemProps {
  component?: string;
  href?: string;
  button: boolean | undefined;
};

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(1) / 4}px
    ${props => props.theme.spacing(4)}px;
  background: ${props => props.theme.palette.common.white};
  position: relative;
`;


function Footer() {

  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            {/*<List>*/}
            {/*  <ListItem button={true} component="a" href="#">*/}
            {/*    <ListItemText primary={t('footer.support')} />*/}
            {/*  </ListItem>*/}
            {/*  <ListItem button={true} component="a" href="#">*/}
            {/*    <ListItemText primary={t('footer.Help Center')} />*/}
            {/*  </ListItem>*/}
            {/*  <ListItem button={true} component="a" href="#">*/}
            {/*    <ListItemText primary={t('footer.Privacy')} />*/}
            {/*  </ListItem>*/}
            {/*  <ListItem button={true} component="a" href="#">*/}
            {/*    <ListItemText primary={t('footer.Terms of Service')}/>*/}
            {/*  </ListItem>*/}
            {/*</List>*/}
          </Grid>
        </Hidden>
        {/* <Grid container item xs={12} md={12} justify="flex-end">
          <List>
            <ListItem button={true} >
              <ListItemText primary={`© ${new Date().getFullYear()} -`} />
              </ListItem>
              <ListItem button={true}>
                <ListItemText primary={t('footer.title')}/>
                </ListItem>
          </List>
        </Grid> */}
      </Grid>
    </Wrapper>
  );
}

export default Footer;
