import React from "react";
import async from "../components/Async";
import { Home, Users,Clock, Briefcase, FilePlus, FileText } from "react-feather";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const ForgotPassword = async(() => import("../pages/auth/ForgotPassword"))
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const Main = async(() => import("../pages/auth/Main"));
// Dashboards components

const NewTender = async(() => import("../pages/Tender/newTender"));
const EditTender = async(() => import("../pages/Tender/editTender"));
const TenderList = async(() => import("../pages/TenderList/index"));
const TenderListIDView = async(() => import("../pages/TenderList/TenderById"));
const UserLists = async(() => import("../pages/users/index"));
const Adduser = async(() => import("../pages/users/adduser"));
const ClientList = async(() => import("../pages/Clients/index"));
const AddClient = async(() => import("../pages/Clients/addClient"));
const EditClient = async(() => import("../pages/Clients/editClient"));
const mom = async(() => import("../pages/mom/index"));


const dashboardsRoutes = {
  id: "View Tenders",
  path: "/home",
  icon: <Home />,
  containsHome: true,
  children: null,
  component: TenderList
};

const TenderRoutes = {
  id: "Tender Registration",
  path: "/New",
  icon: <FilePlus />,
  containsHome: true,
  children: null,
  component: NewTender
};


const EditTenderRoutes = {
  id: "Edit Tender",
  path: "/edit",
  icon: <FileText />,
  containsHome: true,
  children: null,
  component: EditTender
};


const TenderViewRoutes = {
  id: "Edit Tender",
  path: "/view",
  containsHome: true,
  children: null,
  component: TenderListIDView
};


const ClientRoutes = {
  id: "ClientList",
  path: "/clientList",
  icon: <Briefcase />,
  containsHome: true,
  children: null,
  component: ClientList

}

const EditClientRoutes = {
  id: "ClientList",
  path: "/edit/clientList",
  icon: <Users />,
  containsHome: true,
  children: null,
  component: EditClient

}
const AddClientRoutes = {
  id: "ClientList",
  path: "/clientList/add",
  icon: <Users />,
  containsHome: true,
  children: null,
  component: AddClient

}


const UserList = {
  id: "UserList",
  path: "/userlist",
  icon: <Users />,
  component: UserLists,
  children: null
};

const createUsers = {
  id: "create",
  path: "/create/user",
  component: Adduser,
  children: null
};

const MOM = {
  id: "MOM",
  path: "/mom/create",
  icon: <Clock />,
  component: mom,
  children: null
};


const authRoutes = {
  id: "Auth",
  path: "/",
  icon: <Users />,
  children: [
    {
      path: "/",
      name: "Home",
      component: Main
    },
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/forgot-password",
      name: "Forgot Password",
      component: ForgotPassword
    },

    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ],
  component: null
};


// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  TenderRoutes,
  // ProjectSynopsisRoutes,
  EditTenderRoutes,
  UserList,
  ClientRoutes,
  EditClientRoutes,
  // SiteRoutes,
  createUsers,
  AddClientRoutes,
  MOM

];

export const dashboardLayoutRoutesNoSidebar = [

  TenderRoutes,
  EditTenderRoutes,
  UserList,
  ClientRoutes,
  EditClientRoutes,
  // SiteRoutes,
  createUsers,
  TenderViewRoutes,
  AddClientRoutes,
  // ProjectSynopsisRoutes,
  MOM

];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  // ProjectSynopsisRoutes,
  TenderRoutes,
  // EditTenderRoutes,
  UserList,
  ClientRoutes,
  // SiteRoutes,
  MOM
  // EditClientRoutes
];
