import { userConstants } from "../constants/user.constants";
import { profileConstants } from "../constants/profile.constants";

//@ts-ignore
let user = JSON.parse(localStorage.getItem('user'));
//@ts-ignore
let token = (JSON.parse(localStorage.getItem('token')));

const initialState = {
    user: user ? user : {},
    token: token ? token : '',
    isUserLoggedIn: !!token,
    loginStatus: true,
    message: ''
};

export type AuthInitialStateType = {
    user: object,
    token: string,
    isUserLoggedIn: boolean,
    loginStatus: boolean,
    message: string
}
export default function reducer(state = initialState, action: { type: any, user: any, error: any }): AuthInitialStateType {
    console.log("action", action)
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                isUserLoggedIn: false,
            };
        case userConstants.LOGIN_SUCCESS:
            let data = action.user && action.user.data ? action.user.data : {}
            let user = data ? data.user : [];
            let token = data && data.token ? data.token : ''
            console.log("user,token", data, token)
            return {
                ...state,
                user,
                token,
                isUserLoggedIn: !!token,
                loginStatus: true

            };

        case userConstants.UPDATE_SUCCESS:
            return {
                ...state,
                isUserLoggedIn: true,
            };

        case userConstants.REGISTER_SUCCESS:
            return {
                ...state,
                message: action.user.message
            }
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                isUserLoggedIn: false,
                loginStatus: false,
                message: action.error
            };

        case userConstants.REGISTER_FAILURE:
            return {
                ...state,
                message: action.error
            };

        case profileConstants.FAILURE_SELF_INFORMATION:
            localStorage.setItem('isUserLoggedIn', String(Boolean(false)))
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            return {
                ...state,
                //@ts-ignore
                user: {},
                token: '',
                isUserLoggedIn: false,
                //@ts-ignore
                selfInformationFetching: false,
            };

        case userConstants.LOGOUT:
            localStorage.setItem('isUserLoggedIn', String(Boolean(false)))
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            return {
                ...state,
                user: {},
                token: '',
                isUserLoggedIn: false,
            };


        default:
            return state;
    }
}

