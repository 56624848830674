export const tenderConstants = {
  ADDTENDER_REQUEST: 'ADDTENDER_REQUEST',
  ADDTENDER_SUCCESS: 'ADDTENDER_SUCCESS',
  ADDTENDER_FAILURE: 'ADDTENDER_FAILURE',
  TENDERLIST_REQUEST: 'TENDERLIST_REQUEST',
  TENDERLIST_SUCCESS: 'TENDERLIST_SUCCESS',
  TENDERLIST_FAILURE: 'TENDERLIST_FAILURE',
  ARCHIVETENDERLIST_REQUEST: 'ARCHIVETENDERLIST_REQUEST',
  ARCHIVETENDERLIST_SUCCESS: 'ARCHIVETENDERLIST_SUCCESS',
  ARCHIVETENDERLIST_FAILURE: 'ARCHIVETENDERLIST_FAILURE',

  TENDERDATA_REQUEST: 'TENDERDATA_REQUEST',
  TENDERDATA_SUCCESS: 'TENDERDATA_SUCCESS',
  TENDERDATA_FAILURE: 'TENDERDATA_FAILURE',
  UPDATETENDER_REQUEST: 'UPDATETENDER_SUCCESS',
  UPDATETENDER_SUCCESS: 'UPDATETENDER_SUCCESS',
  UPDATETENDER_FAILURE: 'UPDATETENDER_FAILURE',
  TENDERDELETE_REQUEST: 'TENDERDELETE_REQUEST',
  TENDERDELETE_SUCCESS: 'TENDERDELETE_SUCCESS',
  TENDERDELETE_FAILURE: 'TENDERDELETE_FAILURE',
  ADDMOM_REQUEST: 'ADDMOM_REQUEST',
  ADDMOM_SUCCESS: 'ADDMOM_SUCCESS',
  ADDMOM_FAILURE: 'ADDMOM_FAILURE',
  FETCHMOM_REQUEST: 'FETCHMOM_REQUEST',
  FETCHMOM_SUCCESS: 'FETCHMOM_SUCCESS',
  FETCHMOM_FAILURE: 'FETCHMOM_FAILURE',



};