import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

/*
 * Create the i18next instance.
 *
 * For documentation, see: https://react.i18next.com/components/i18next-instance.html
 */
export const i18n = (language?: string) => i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    lng: language,
    ns: ['app'],
    defaultNS: 'app',
    debug: true,
    interpolation: {
      prefix: '{',
      suffix: '}'
    },
    react: {
    },
    resources: {
      en: require('./en.json'),
      ori: require('./ori.json')
    }
  });
