export const profileConstants = {
  RESET_SNACKBAR_TYPE: 'RESET_SNACKBAR_TYPE',
  REQUEST_UPDATE_APPLICATION_STATUS: 'REQUEST_UPDATE_APPLICATION_STATUS',
  SUCCESS_UPDATE_APPLICATION_STATUS: 'SUCCESS_UPDATE_APPLICATION_STATUS',
  FAILURE_UPDATE_APPLICATION_STATUS: 'FAILURE_UPDATE_APPLICATION_STATUS',


  REQUEST_UPDATE_SINGLE_APPLICATION_STATUS: 'REQUEST_UPDATE_SINGLE_APPLICATION_STATUS',
  SUCCESS_UPDATE_SINGLE_APPLICATION_STATUS: 'SUCCESS_UPDATE_SINGLE_APPLICATION_STATUS',
  FAILURE_UPDATE_SINGLE_APPLICATION_STATUS: 'FAILURE_UPDATE_SINGLE_APPLICATION_STATUS',


  REQUEST_SELF_INFORMATION: 'REQUEST_SELF_INFORMATION',
  SUCCESS_SELF_INFORMATION: 'SUCCESS_SELF_INFORMATION',
  FAILURE_SELF_INFORMATION: 'FAILURE_SELF_INFORMATION',
  RESET_SELF_DATA: "RESET_SELF_DATA",

  REQUEST_FETCH_CROPS: 'REQUEST_FETCH_CROPS',
  SUCCESS_FETCH_CROPS: 'SUCCESS_FETCH_CROPS',
  FAILURE_FETCH_CROPS: 'FAILURE_FETCH_CROPS',



};
