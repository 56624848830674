export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',
  LOGOUT: 'USERS_LOGOUT',
  USERLIST_REQUEST: 'USERLIST_REQUEST',
  USERLIST_SUCCESS: 'USERLIST_SUCCESS',
  USERLIST_FAILURE: 'USERLIST_FAILURE',
  USERDATA_REQUEST: 'USERDATA_REQUEST',
  USERDATA_SUCCESS: 'USERDATA_SUCCESS',
  USERDATA_FAILURE: 'USERDATA_FAILURE',
  UPDATEUSER_REQUEST: 'UPDATEUSER_SUCCESS',
  UPDATEUSER_SUCCESS: 'UPDATEUSER_SUCCESS',
  UPDATEUSER_FAILURE: 'UPDATEUSER_FAILURE',
  USERDELETE_REQUEST: 'USERDELETE_REQUEST',
  USERDELETE_SUCCESS: 'USERDELETE_SUCCESS',
  USERDELETE_FAILURE: 'USERDELETE_FAILURE',
  SEARCHUSER_REQUEST: 'SEARCHUSER_REQUEST',
  SEARCHUSER_SUCCESS: 'SEARCHUSER_SUCCESS',
  SEARCHUSER_FAILURE: 'SEARCHUSER_FAILURE'


};