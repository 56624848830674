import { tenderConstants } from "../constants/tenderConstant";
import { userConstants } from "../constants/user.constants";
import { clientConstants } from "../constants/client.constants";


const initialState = {
  isLoading: false,
  msg: '',
  tenderList: [],
  userList: [],
  clientList: [],
  userData: [],
  TenderData: {},
  searchUser: [],
  clientData: {},
  archiveTenderList: [],
  momList: [],

  // fetchProfileCountError
  fetchProfileCountError: null,

  //InsertApplicationNotification
  InsertNotification: null

};

export default function reducer(state = initialState, action: { type: any, data: any, locationFetching: boolean }) {
  switch (action.type) {
    case tenderConstants.ADDTENDER_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
      };

    case tenderConstants.ADDTENDER_SUCCESS:
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        // msg: action.user.data.message
      };

    case tenderConstants.ADDTENDER_FAILURE:
      //@ts-ignore
      console.log("Comes in failure", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        //@ts-ignore
        // msg: action.data.msg
      };

    case tenderConstants.TENDERDATA_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
      };

    case tenderConstants.TENDERDATA_SUCCESS:
      console.log("TenderData fetch", action)
      return {
        ...state,
        //@ts-ignore
        TenderData: action.data && action.data.data,
        isLoading: false,
        // msg: action.user.data.message
      };

    case tenderConstants.TENDERDATA_FAILURE:
      //@ts-ignore
      console.log("Comes in failure", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        //@ts-ignore
        // msg: action.data.msg
      };

    case tenderConstants.TENDERLIST_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
      };

    case tenderConstants.TENDERLIST_SUCCESS:
      console.log("SUCCESS_FETCH_PROFILE_TENDER", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        tenderList: action.data.data.data,
        msg: action.data.data.message
      };

    case tenderConstants.TENDERLIST_FAILURE:
      //@ts-ignore
      console.log("Comes in failure", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        // msg: action.user.data.message
      };



    case tenderConstants.ARCHIVETENDERLIST_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
      };

    case tenderConstants.ARCHIVETENDERLIST_SUCCESS:
      console.log("SUCCESS_FETCH_ARCHIVE_TENDER", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        archiveTenderList: action.data.data,
        msg: action.data.data.message
      };

    case tenderConstants.ARCHIVETENDERLIST_FAILURE:
      //@ts-ignore
      console.log("Comes in failure", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        // msg: action.user.data.message
      };



    case tenderConstants.ADDMOM_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
      };

    case tenderConstants.ADDMOM_SUCCESS:
      console.log("SUCCESS_FETCH_PROFILE_TENDER", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        msg: action.data.message
      };

    case tenderConstants.ADDMOM_FAILURE:
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
      };


    case tenderConstants.FETCHMOM_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
      };

    case tenderConstants.FETCHMOM_SUCCESS:
      console.log("MOMFETCH", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        msg: action.data.message,
        momList: action.data.data,
      };

    case tenderConstants.FETCHMOM_FAILURE:
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
      };


    case userConstants.USERDATA_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
      };

    case userConstants.USERDATA_SUCCESS:
      console.log("TenderData fetch", action)
      return {
        ...state,
        //@ts-ignore
        userData: action.data.data && action.data.data.data && action.data.data.data.length > 0 && action.data.data.data[0],
        isLoading: false,
        // msg: action.user.data.message
      };

    case userConstants.USERDELETE_FAILURE:
      //@ts-ignore
      console.log("Comes in failure", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        //@ts-ignore
        // msg: action.data.msg
      };


    case userConstants.USERLIST_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
      };

    case userConstants.USERLIST_SUCCESS:
      console.log("SUCCESS_FETCH_PROFILE_TENDER", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        userList: action.data.data.data,
        msg: action.data.data.message
      };

    case userConstants.USERLIST_FAILURE:
      //@ts-ignore
      console.log("Comes in failure", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        // msg: action.user.data.message
      };

    case userConstants.SEARCHUSER_SUCCESS:
      console.log("action", action)
      return {
        ...state,
        isLoading: false,
        searchUser: action.data.data
      }


    case clientConstants.CLIENTLIST_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
      };

    case clientConstants.CLIENTLIST_SUCCESS:
      console.log("SUCCESS_FETCH_PROFILE_TENDER", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        clientList: action.data.data.data,
        msg: action.data.data.message
      };

    case clientConstants.CLIENTLIST_FAILURE:
      //@ts-ignore
      console.log("Comes in failure", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        // msg: action.user.data.message
      };

    case clientConstants.ADDCLIENT_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
        msg: ''
      };

    case clientConstants.ADDCLIENT_SUCCESS:
      console.log("SUCCESS_FETCH_PROFILE_TENDER", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        msg: action.data.data.message
      };

    case clientConstants.ADDCLIENT_FAILURE:
      //@ts-ignore
      console.log("Comes in failure", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        // msg: action.user.data.message
      };

    case clientConstants.UPDATECLIENT_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
      };

    case clientConstants.UPDATECLIENT_SUCCESS:
      console.log("SUCCESS_FETCH_PROFILE_TENDER", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        msg: action.data.data.message
      };

    case clientConstants.UPDATECLIENT_FAILURE:
      //@ts-ignore
      console.log("Comes in failure", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        // msg: action.user.data.message
      };

    case clientConstants.CLIENTDELETE_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
      };

    case clientConstants.CLIENTDELETE_SUCCESS:
      console.log("SUCCESS_FETCH_PROFILE_TENDER", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        msg: action.data.data.message
      };

    case clientConstants.CLIENTDELETE_FAILURE:
      //@ts-ignore
      console.log("Comes in failure", action)
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        // msg: action.user.data.message
      };

    case clientConstants.CLIENTDATA_REQUEST:
      return {
        ...state,
        //@ts-ignore
        isLoading: true,
      };

    case clientConstants.CLIENTDATA_SUCCESS:
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        clientData: action.data.data.data,
        msg: action.data.data.message
      };

    case clientConstants.CLIENTDATA_FAILURE:
      return {
        ...state,
        //@ts-ignore
        isLoading: false,
        msg: action.data.data.message
      };

    default:
      return state;
  }
}

