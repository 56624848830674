
// @ts-ignore
import baseUrlConfig from "../../env/baseUrl.config";
import axios from 'axios';

// let applicationId = baseUrlConfig.applicationId

export const clientService = {
    createClient,
    fetchclient,
    updateclient,
    fetchclientById,
    deleteclient
};


async function createClient(data: any, token: any) {
    console.log("data", data)
    const config = {
        method: 'post',
        url: baseUrlConfig.baseURL + '/api/client/createClient',
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: data
    };
    
    // @ts-ignore
    return axios(config)
        // .then(handleResponse)
        .then(user => {
            console.log("successfully login", user)
            if (user.data.message === "success") {
                console.log("hello")

            }
            else {

            }
            return user;
        });
}

async function fetchclient(token: any) {
    const config = {
        method: 'get',
        // url: `${baseUrlConfig.login}/api/login`,
        url: baseUrlConfig.baseURL + '/api/client/getAllClient', //'https://adapt.agriodisha.nic.in/api/api/farmer-db-authenticate',
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: {}
    };

    // @ts-ignore
    return axios(config)
        // .then(handleResponse)
        .then(CLIENT => {
            console.log("successfully login", CLIENT)
            if (CLIENT.data.message === "success") {
                console.log("hello")
            }
            return CLIENT;
        });
}

async function fetchclientById(id: any, token: any) {
    const config = {
        method: 'get',
        // url: `${baseUrlConfig.login}/api/login`,
        url: baseUrlConfig.baseURL + '/api/client/getClientById/' + id, //'https://adapt.agriodisha.nic.in/api/api/farmer-db-authenticate',
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: {}
    };
    // @ts-ignore
    return axios(config)
        // .then(handleResponse)
        .then(data => {
            console.log("successfully login", data)
            if (data.data.message === "success") {
                console.log("hello")
            }
            return data;
        });
}

async function updateclient(data: any, token: any) {
    console.log("data", data)
    const config = {
        method: 'post',
        // url: `${baseUrlConfig.login}/api/login`,
        url: baseUrlConfig.baseURL + '/api/client/update', //'https://adapt.agriodisha.nic.in/api/api/farmer-db-authenticate',
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: data
    };

    // @ts-ignore
    return axios(config)
        // .then(handleResponse)
        .then(CLIENT => {
            console.log("successfully login", CLIENT)
            if (CLIENT.data.message === "success") {
                console.log("hello")
            }
            else {
            }
            return CLIENT;
        });
}

async function deleteclient(id: any, token: any) {
    const config = {
        method: 'post',
        // url: `${baseUrlConfig.login}/api/login`,
        url: baseUrlConfig.baseURL + '/api/client/delete', //'https://adapt.agriodisha.nic.in/api/api/farmer-db-authenticate',
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: { id }
    };

    // @ts-ignore
    return axios(config)
        // .then(handleResponse)
        .then(CLIENT => {
            console.log("deleted successfully", CLIENT)
            if (CLIENT.data.message === "success") {
                console.log("hello")
            }
            return CLIENT;
        });
}



