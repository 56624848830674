// @ts-ignore
import baseUrlConfig from "../../env/baseUrl.config";
import axios from 'axios';

export const userService = {
    login,
    logout,
    changePassword,
    signup,
    fetchUSER,
    updateUSER,
    fetchUSERByid,
    deleteUSER,
    searchUser
};

async function login(username: any, password: any, rememberMe: any) {
    console.log("details", username, password, rememberMe)
    let data = {
        "username": username,
        "password": password
    };
    const config = {
        method: 'post',
        // url: `${baseUrlConfig.login}/api/login`,
        url: baseUrlConfig.baseURL + '/api/user/login', //'https://adapt.agriodisha.nic.in/api/api/farmer-db-authenticate',
        // headers: {
        //     'Authorization': baseUrlConfig.Authorization
        // },
        data: data
    };

    // @ts-ignore
    return axios(config)
        // .then(handleResponse)
        .then(user => {

            // @ts-ignore
            let userMain = user && user.data && user.data.data ? user.data.data : [];
            // @ts-ignore
            let token = user && user.data && user.data.data.token ? user.data.data.token : null;
            console.log("user,token", user, token)
            if (rememberMe) {
                localStorage.setItem('isUserLoggedIn', String(true));
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(userMain));
                localStorage.setItem('token', JSON.stringify(token));
            }
            return user.data;
        });
}

async function signup(username: any, password: any, email: any, role: any) {
    console.log("details", username, password, email)
    let data = {
        "name": username,
        "password": password,
        "email": email,
        "role": role
    };
    const config = {
        method: 'post',
        // url: `${baseUrlConfig.login}/api/login`,
        url: baseUrlConfig.baseURL + '/api/user/createUser', //'https://adapt.agriodisha.nic.in/api/api/farmer-db-authenticate',
        // headers: {
        //     'Authorization': baseUrlConfig.Authorization
        // },
        data: data
    };

    // @ts-ignore
    return axios(config)
        .then(user => {
            return user.data;
        });
}

function logout(token: any) {
    // remove user from local storage to log user out

    // const config = {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${token}`
    //     }
    // };

    // return axios.post(baseUrlConfig.logout + '/api/user/logout', {}, config)
    // .then(user => {
    // @ts-ignore
    localStorage.setItem('isUserLoggedIn', false)
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    // return [];
    // }).catch(e => {
    // @ts-ignore
    localStorage.setItem('isUserLoggedIn', false)
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.removeItem('user');
    return [];
    // })

}

// @ts-ignore
function changePassword(token, fields) {
    console.log("authToken", token);
    console.log("fields", fields);
    // const config = {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': token
    //     }
    // };

    // return axios.post(baseUrlConfig.changePassword + '/api/user/password/update', {
    //     oldPassword: fields.oldPassword,
    //     newPassword: fields.newPassword,
    // }, config)
    //     .then(
    //         user => {
    //             console.log("user", user)
    //             return user;
    //         }, error => {
    //             console.log("error", error)
    //             return error;
    //         });

}


async function fetchUSER(token: any) {
    const config = {
        method: 'get',
        // url: `${baseUrlConfig.login}/api/login`,
        url: baseUrlConfig.baseURL + '/api/user/getAllUsers', //'https://adapt.agriodisha.nic.in/api/api/farmer-db-authenticate',
        headers: {
            //@ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: {}
    };

    // @ts-ignore
    return axios(config)
        // .then(handleResponse)
        .then(user => {
            console.log("successfully login", user)
            if (user.data.message === "success") {
                console.log("hello")
            }
            return user;
        });
}

async function fetchUSERByid(id: any, token: any) {
    const config = {
        method: 'get',
        url: baseUrlConfig.baseURL + '/user/get/' + id,
        headers: {
            //@ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: {}
    };
    // @ts-ignore
    return axios(config)
        // .then(handleResponse)
        .then(data => {
            console.log("successfully login", data)
            if (data.data.message === "success") {
                console.log("hello")
            }
            return data;
        });
}


async function updateUSER(data: any, token: any) {
    console.log("data", data)
    const config = {
        method: 'post',
        url: baseUrlConfig.baseURL + '/api/user/update',
        headers: {
            //@ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: data
    };

    // @ts-ignore
    return axios(config)
        .then(user => {
            console.log("successfully login", user)
            if (user.data.message === "success") {
                console.log("hello")
            }
            else {
            }
            return user;
        });
}

async function deleteUSER(id: any, token: any) {
    const config = {
        method: 'post',
        url: baseUrlConfig.baseURL + '/api/user/delete',
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: { id }
    };
    // @ts-ignore
    return axios(config)
        // .then(handleResponse)
        .then(user => {
            console.log("deleted successfully", user)
            if (user.data.message === "success") {
                console.log("hello")
            }
            return user;
        });
}

async function searchUser(key: any, token: any) {
    const config = {
        method: 'post',
        url: baseUrlConfig.baseURL + '/api/user/search',
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: { key: key }
    };
    // @ts-ignore
    return axios(config)
        // .then(handleResponse)
        .then(user => {
            return user.data;
        });
}



