// @ts-ignore
import baseUrlConfig from "../../env/baseUrl.config";
import axios from 'axios';

export const tenderService = {
    addtender,
    fetchtender,
    updatetender,
    fetchtenderByid,
    deleteTender,
    addMOM,
    fetchMOMByid,
    fetchTenderArchiveById
};



async function addMOM(data: any, token: any) {
    console.log("data", data.tender_Id)
    const config = {
        method: 'post',
        url: baseUrlConfig.baseURL + '/api/mom/create',
        data: data,
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
        },

    };

    // @ts-ignore
    return axios.post(config.url, data, { headers: config.headers })
        // .then(handleResponse)
        .then(user => {
            console.log("successfully login", user)
            if (user.data.message === "success") {
                console.log("hello")

            }
            else {

            }
            return user;
        })
}

async function fetchtender(token: any) {
    console.log("methid", token)
    console.log("methid", localStorage.getItem('token'))
    const config = {
        method: 'get',
        url: baseUrlConfig.baseURL + '/api/tender/getAllTender',
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: {}
    };
    console.log("methid", config)
    // @ts-ignore 
    return axios(config)
        .then(user => {
            console.log("successfully tender", user)
            if (user.data.message === "success") {
                console.log("hello")
            }
            return user;
        });
}

async function fetchtenderByid(id: any, token: any) {
    const config = {
        method: 'get',
        url: baseUrlConfig.baseURL + '/api/tender/getTenderById/' + id,
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: {}
    };
    // @ts-ignore
    return axios(config)
        .then(tender => {
            return tender.data;
        });
}

async function fetchTenderArchiveById(id: any, token: any) {
    const config = {
        method: 'get',
        url: baseUrlConfig.baseURL + '/api/tender/getTenderArchiveById/' + id,
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: {}
    };
    // @ts-ignore
    return axios(config)
        .then(tender => {
            return tender.data;
        });
}

async function fetchMOMByid(id: any, token: any) {
    const config = {
        method: 'get',
        url: baseUrlConfig.baseURL + '/api/mom/getMoM/' + id,
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: {}
    };
    // @ts-ignore
    return axios(config)
        .then(tender => {
            return tender.data;
        });
}

async function addtender(data: any, token: any) {
    console.log("data", data)
    const config = {
        method: 'post',
        url: baseUrlConfig.baseURL + '/api/tender/createTender',
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
        },
        data: data
    };

    // @ts-ignore
    return axios.post(config.url, data, { headers: config.headers })
        // .then(handleResponse)
        .then(user => {
            console.log("successfully login", user)
            if (user.data.message === "success") {
                console.log("hello")

            }
            else {

            }
            return user;
        }).catch(error => {
            console.log("error", error.message)
        });;
}

async function updatetender(data: any, token: any) {
    console.log("data", data)
    const config = {
        method: 'post',
        url: baseUrlConfig.baseURL + '/api/tender/update/',
        data: data,
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
        },
    };

    // @ts-ignore
    return axios.post(config.url, data, { headers: config.headers })
        // .then(handleResponse)
        .then(user => {
            return user;
        });
}

async function deleteTender(id: any, token: any) {
    const config = {
        method: 'post',
        url: baseUrlConfig.baseURL + '/api/tender/delete',
        headers: {
            // @ts-ignore
            'auth': token ? token : JSON.parse(localStorage.getItem('token'))
        },
        data: { id }
    };
    // @ts-ignore
    return axios(config)
        // .then(handleResponse)
        .then(user => {
            return user;
        });
}

