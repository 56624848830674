import * as React from "react";
import { useTranslation } from "react-i18next";
import styled, { withTheme } from "styled-components";
import { connect, useDispatch } from "react-redux";
import {
  Grid,
  Hidden,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import {
  Power
} from "react-feather";
import { Link } from "react-router-dom";
import { authActions } from "../redux/actions/authActions";
import Typography from "@material-ui/core/Typography";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;


const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

// let {t} = this.props

function LanguageMenu() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const { t, i18n } = useTranslation();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const setEnglish = () => {
    setAnchorMenu(null);
    i18n.changeLanguage('en');
  }

  const setOriya = () => {
    setAnchorMenu(null);
    i18n.changeLanguage('ori');
  }
  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Flag src="/static/img/language-selection.png" alt="img" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={setEnglish}>
          {t('header.eng_lang')}
        </MenuItem>
        <MenuItem onClick={setOriya}>
          {t('header.odi_lang')}
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

function UserMenu() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleLogout = () => {
    setAnchorMenu(null);
    // @ts-ignore
    dispatch(authActions.logout());

  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Power />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {/* <MenuItem onClick={closeMenu}>
          {t('header.profile')}
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          <Link to={'/auth/sign-in'} style={{ textDecoration: 'none', color: 'inherit' }} >
            {t('header.sign out')}
            {/* Sign out */}
          </Link>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

type HeaderProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;

}
const Header: React.FC<HeaderProps> = ({ onDrawerToggle }) => (

  <React.Fragment>

    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item>
            <Typography className="header-title" variant="h6" display="inline">
              Tender Management Application
              {/* {t('header.title')} */}

            </Typography>
            {/*<Search>*/}
            {/*  <SearchIconWrapper>*/}
            {/*    <SearchIcon />*/}
            {/*  </SearchIconWrapper>*/}
            {/*  <Input placeholder="Search topics" />*/}
            {/*</Search>*/}
          </Grid>
          <Grid item xs />
          <Grid item>
            {/* <IconButton color="inherit">
              <Indicator badgeContent={3}>
                <MessageSquare />
              </Indicator>
            </IconButton>
            <IconButton color="inherit">
              <Indicator badgeContent={7}>
                <Bell />
              </Indicator>
            </IconButton> */}

            <LanguageMenu />
            <UserMenu />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

export default connect()(withTheme(Header));
