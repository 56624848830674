import { combineReducers } from 'redux';

import themeReducer from './themeReducers';
import authReducer from './authReducers';
import profileReducer from './profileReducers';

export const rootReducer = combineReducers({
	authReducer :authReducer,
	themeReducer :themeReducer,
  profileReducer :profileReducer,
});

type RootReducerType = typeof rootReducer
export type AppStateType = ReturnType<RootReducerType>
