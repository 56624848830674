export const clientConstants = {
    
    ADDCLIENT_REQUEST: 'ADDCLIENT_REQUEST',
    ADDCLIENT_SUCCESS: 'ADDCLIENT_SUCCESS',
    ADDCLIENT_FAILURE: 'ADDCLIENT_FAILURE',
    
    CLIENTLIST_REQUEST: 'CLIENTLIST_REQUEST',
    CLIENTLIST_SUCCESS: 'CLIENTLIST_SUCCESS',
    CLIENTLIST_FAILURE: 'CLIENTLIST_FAILURE',

    CLIENTDATA_REQUEST: 'CLIENTDATA_REQUEST',
    CLIENTDATA_SUCCESS: 'CLIENTDATA_SUCCESS',
    CLIENTDATA_FAILURE: 'CLIENTDATA_FAILURE',
    
    UPDATECLIENT_REQUEST:'UPDATECLIENT_SUCCESS',
    UPDATECLIENT_SUCCESS:'UPDATECLIENT_SUCCESS',
    UPDATECLIENT_FAILURE:'UPDATECLIENT_FAILURE',
    
    CLIENTDELETE_REQUEST:'CLIENTDELETE_REQUEST',
    CLIENTDELETE_SUCCESS:'CLIENTDELETE_SUCCESS',
    CLIENTDELETE_FAILURE:'CLIENTDELETE_FAILURE',
  };