import React from "react";
import { connect } from "react-redux";

import { Helmet } from 'react-helmet';

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";

import maTheme from "./theme";
import Routes from "./routes/Routes";
import { AppStateType } from "./redux/reducers";
import { ThemeInitialStateType } from "./redux/reducers/themeReducers";
import jwt_decode from "jwt-decode"
import './App.css'
function App({ theme }: { theme: ThemeInitialStateType }) {
  let token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
  let curentDate = new Date();
  // @ts-ignore
  var decoded: any = token ? jwt_decode(token, { complete: true }) : '';
  if (decoded && decoded.exp * 1000 < curentDate.getTime()) {
    localStorage.removeItem('token')
  }
  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Tender Management Application" defaultTitle="Tender Management Application"
      />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default connect((store: AppStateType) => ({ theme: store.themeReducer }))(App);
